import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';
import { TextField } from '@mui/material';
import { AiFillLinkedin } from 'react-icons/ai';
import ReactGA from "react-ga4";


import styles from "./styles.module.css";
import pifod from "./pifoldlogo.png";
import Canvas from "./../components/Canvas";
import BrainSVG from "./../components/Brain";
import CochabambaSVG from "../components/Cochabamba";
import CloudDotsSVG from "../components/CloudDots";
import IdeaSVG from "../components/Idea";
import SoftwareSVG from "../components/Software";
import drawMatrixEffect from "../particles/Matrix";
import MeetOurTeam from '../components/MeetOurTeam';

// Constants
const ALERT_TIMEOUT = 3000;
const API_ENDPOINT = "http://localhost:3001/send";
const GA_TRACKING_ID = "G-W8BYRFD4P0"; // Replace with your Google Analytics tracking ID

const SECTIONS = [
  { id: "AboutUs", title: "About Us" },
  { id: "ArtificialIntelligence", title: "Artificial Intelligence" },
  { id: "DataScience", title: "Data Science" },
  { id: "AIDevelopment", title: "AI Development" },
//   { id: "Projects", title: "Projects" },
//   { id: "OurTeam", title: "Our Team" },
//   { id: "ContactUs", title: "Contact Us" }
];

// Custom hooks
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
  // Initialize Google Analytics
  ReactGA.initialize(GA_TRACKING_ID);

  // Track initial page view
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: document.title,
  });
}, []);


  return windowSize;
};

const useFormValidation = (initialValues) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setValues(prev => ({ ...prev, [name]: value }));
  }, []);

  const validateForm = useCallback(() => {
    const newErrors = {};
    
    // Name validation
    if (!values.name.trim()) {
      newErrors.name = "Name is required";
    }
    
    // Email validation
    if (!values.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      newErrors.email = "Email is invalid";
    }
    
    // Message validation
    if (!values.message.trim()) {
      newErrors.message = "Message is required";
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [values]);

  const resetForm = useCallback(() => {
    setValues(initialValues);
    setErrors({});
    setIsSubmitting(false);
  }, [initialValues]);

  return {
    values,
    errors,
    isSubmitting,
    setIsSubmitting,
    handleChange,
    validateForm,
    resetForm
  };
};

const useAlertMessage = () => {
  const [alert, setAlert] = useState({ show: false, message: "", variant: "info" });

  const showAlert = useCallback((message, variant = "info") => {
    setAlert({ show: true, message, variant });
    setTimeout(() => {
      setAlert(prev => ({ ...prev, show: false }));
    }, ALERT_TIMEOUT);
  }, []);

  return { alert, showAlert };
};

// Memoized components
const Section = React.memo(({ id, height, children, className = "" }) => (
  <div id={id} style={{ position: "relative", height }} className={className}>
    {children}
  </div>
));

const ContactForm = React.memo(({ formState, handleChange, handleSubmit, errors }) => (
  <form className={styles.form} onSubmit={handleSubmit} noValidate>
    <div className="form-floating mb-3">
      <TextField
        name="name"
        value={formState.name}
        onChange={handleChange}
        required
        fullWidth
        margin='normal'
        id="name-input"
        label="Name"
        variant="filled"
        error={!!errors.name}
        helperText={errors.name}
        inputProps={{ "aria-label": "Name" }}
      />
    </div>
    <div className="form-floating mb-3">
      <TextField
        name="email"
        value={formState.email}
        onChange={handleChange}
        required
        fullWidth
        margin='normal'
        id="email-input"
        label="Email"
        variant="filled"
        error={!!errors.email}
        helperText={errors.email}
        inputProps={{ "aria-label": "Email" }}
      />
    </div>
    <div className="form-floating mb-3">
      <TextField
        name="message"
        value={formState.message}
        onChange={handleChange}
        required
        fullWidth
        multiline
        rows={3}
        margin='normal'
        id="message-input"
        label="Say something nice!"
        variant="filled"
        error={!!errors.message}
        helperText={errors.message}
        inputProps={{ "aria-label": "Message" }}
      />
    </div>
    <div className={styles.formCTA}>
      <button type="submit" className="btn btn-primary" aria-label="Send message">
        SEND
      </button>
    </div>
  </form>
));

const AlertMessage = React.memo(({ alert }) => (
  <Alert 
    show={alert.show} 
    variant={alert.variant}
    aria-live="polite"
  >
    {alert.message}
  </Alert>
));

const NavigationBar = React.memo(() => (
  <Navbar fixed='top' collapseOnSelect expand="lg" className={styles.buttons}>
    <Container>
      <Navbar.Brand href="#home" className={styles.logoPi}>
        PiFold
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className={`${styles.button} me-auto`}>
          {SECTIONS.map(section => (
            <Nav.Link key={section.id} href={`#${section.id}`}>
              {section.title}
            </Nav.Link>
          ))}
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
));

// Main component
const Main = () => {
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const pages = 8;

  const sectionHeight = useMemo(() => windowHeight, [windowHeight]);
  const backgroundHeight = useMemo(() => windowHeight * pages, [windowHeight, pages]);

  const { values: formState, errors, isSubmitting, setIsSubmitting, handleChange, validateForm, resetForm } = 
    useFormValidation({ name: "", email: "", message: "" });
  
  const { alert, showAlert } = useAlertMessage();

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      showAlert("Please correct the form errors", "danger");
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      const response = await fetch(API_ENDPOINT, {
        method: "POST",
        headers: {
          "Accept": "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formState),
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      showAlert("Thank you for submitting your response", "success");
      resetForm();
    } catch (error) {
      console.error("Submission error:", error);
      showAlert("Couldn't send the message, please try again later", "danger");
    } finally {
      setIsSubmitting(false);
    }
  }, [formState, validateForm, resetForm, showAlert, setIsSubmitting]);

  // Content sections data
  const contentSections = useMemo(() => [
    {
      id: "AboutUs",
      isReverse: true,
      SvgComponent: CochabambaSVG,
      title: "About Us",
      content: "Pifold is a team of exceptionally talented individuals driven by innovation. We leverage artificial intelligence and data science to develop cutting-edge solutions, creating a strong competitive advantage in every product we build."
    },
    {
      id: "ArtificialIntelligence",
      isReverse: false,
      SvgComponent: BrainSVG,
      title: "Artificial Intelligence",
      content: "Artificial Intelligence (AI) is transforming the way we live, work, and innovate. From automating tasks to uncovering deep insights, AI enhances efficiency, decision-making, and creativity across industries. At Pifold, we harness AI to create intelligent solutions that drive progress and unlock new possibilities."
    },
    {
      id: "DataScience",
      isReverse: true,
      SvgComponent: CloudDotsSVG,
      title: "Data Science",
      content: "Data science is the key to turning raw information into actionable insights. By analyzing patterns, predicting trends, and optimizing decisions, it empowers businesses to innovate and stay ahead. At Pifold, we leverage data science to create intelligent solutions that drive success and transformation."
    },
    {
      id: "AIDevelopment",
      isReverse: false,
      SvgComponent: IdeaSVG,
      title: "AI Development",
      content: "AI development enables us to solve complex problems across diverse industries, from automation and predictive analytics to natural language processing and computer vision. At Pifold, we build intelligent solutions that drive innovation, efficiency, and competitive advantage in every field. Contact us if you need help with AI development."
    },
    // {
    //   id: "Projects",
    //   isReverse: true,
    //   SvgComponent: IdeaSVG,
    //   title: "Projects",
    //   content: "We have the right team to bring any ideas to reality, our team brings together a combination of different skills in order to make a difference with the competitive advantage which is the most important in a new technology startup."
    // }
  ], []);

  return (
    <div className={styles.generalStyles}>
      <NavigationBar />
      
      <Canvas
        draw={drawMatrixEffect}
        height={windowHeight}
        width={windowWidth}
        className={styles.background}
      />

      <Section id="home" height={sectionHeight}>
        <div className={styles.banner}>
          <img src={pifod} alt="PiFold logo" />
          <p className={styles.scrollText}>
            We make magic things with Data and Artificial Intelligence.
          </p>
        </div>
      </Section>

      {contentSections.map(section => (
        <Section key={section.id} id={section.id} height={sectionHeight}>
          <div className={section.isReverse ? styles.containerReverse : styles.container}>
            {section.isReverse && (
              <div className={styles.svgContainer}>
                <section.SvgComponent height={400} width={section.id === "DataScience" ? 500 : 400} />
              </div>
            )}
            <div className={styles.textContainer}>
              <h1 className={styles.heading}>{section.title}</h1>
              <p className={styles.info}>{section.content}</p>
            </div>
            {!section.isReverse && (
              <div className={styles.svgContainer}>
                <section.SvgComponent height={400} width={400} />
              </div>
            )}
          </div>
        </Section>
      ))}

      {/* <Section id="OurTeam">
        <div className={styles.teamContainer}>
          <h1 className={styles.heading}>Meet Our Team</h1>
          <MeetOurTeam />
        </div>
      </Section> */}

      {/* <Section id="ContactUs" height={sectionHeight}>
        <div className={styles.formContainer}>
          <div className={styles.cyberpunk}>
            <h1 className={styles.heading}>Contact Us</h1>
            <ContactForm 
              formState={formState}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              errors={errors}
            />
            <AlertMessage alert={alert} />
          </div>
        </div>
      </Section> */}

      <footer>
		<div className={styles.socialLinks}>
			<a 
				href="https://www.linkedin.com/company/pifold" 
				target="_blank" 
				rel="noopener noreferrer" 
				aria-label="PiFold LinkedIn Profile"
				className={styles.socialIcon}
			>
				<AiFillLinkedin size={54} />
			</a>
		</div>
		<br />
        <small>&copy; Copyright {new Date().getFullYear()}, PiFold. All rights reserved.</small>
      </footer>
    </div>
  );
};

export default Main;
