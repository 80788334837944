const drawMatrixEffect = (ctx, canvas) => {
    window.addEventListener('resize', () => {
        canvas.width = window.innerWidth
        canvas.height = window.innerHeight
        effect.resize(canvas.width, canvas.height)
    })
    class Symbol{
        constructor(x, y, fontSize, canvasHeight){
            // this.characters = " \u03C0\u03BB\u2202\u221E\u2211\u03B2\u222B\u221D\u20BF01PIFOLD"
            this.characters = " \u03C0\u03BB\u20BF01PIFOLD"
            this.x = x
            this.y = y
            this.fontSize = fontSize
            this.text = ''
            this.canvasHeight = canvasHeight
            this.position = 0
            this.nextChar = false
        }
        isFromPifold(char){
            return "PIFOLD".indexOf(char) > -1
        }
        draw(){
            let pos = Math.floor(Math.random()*(this.characters.length-5))
            pos = pos === 0 ? pos + 1 : pos
            if(this.isFromPifold(this.text)){
                pos = (this.position + 1) < this.characters.length ? this.position + 1 : 0
                this.text = this.characters.charAt(pos)
            }else{
                if(this.characters.charAt(pos) === 'P' && !this.nextChar){
                    this.nextChar = true
                    this.text = ' '
                    pos = 0
                }
                else{
                    if(this.nextChar){
                        this.text = 'P'
                        pos = this.characters.length-6
                    }else{
                        this.text = this.characters.charAt(pos)
                    }
                    this.nextChar = false
                }
            }
            this.position = pos
            // let scanPixel = ctx.getImageData(this.x * this.fontSize, this.y * this.fontSize, this.fontSize, this.fontSize)
            // if(this.x == 5){
            //     const scannedData = scanPixel.data
            //     let total = 0
            //     for(let i=0; i < scannedData.length; i+=4){
            //         total = total + scannedData[i] + scannedData[i+1] + scannedData[i+2]
            //     }
            //     if(total === 478125){
            //         console.log(scanPixel)
            //     }
            //     // console.log(scanPixel)
            //     // console.log(this.x,this.y)
            //     // console.log(this.x * this.fontSize, this.y * this.fontSize)
            // }
            ctx.fillText(this.text, this.x * this.fontSize, this.y * this.fontSize)
            if(this.y * this.fontSize > this.canvasHeight && Math.random() > 0.99){
                this.y = 0
            }else{
                this.y += 1
            }
        }
    }

    class Effect{
        constructor(canvasWidth, canvasHeight){
            this.canvasWidth = canvasWidth
            this.canvasHeight = canvasHeight
            this.fontSize = 25
            this.columns = this.canvasWidth/this.fontSize
            this.symbols = []
            this.initialize()
            // console.log(this.symbols)
        }
        initialize(){
            for (let i = 0; i < this.columns; i++) {
                this.symbols[i] = new Symbol(i, 0, this.fontSize, this.canvasHeight)
            }
        }
        resize(width, height){
            this.canvasWidth = width
            this.canvasHeight = height
            this.columns = this.canvasWidth/this.fontSize
            this.symbols = []
            this.initialize()
        }
    }

    const effect = new Effect(canvas.width, canvas.height)
    let lastTime = 0
    const fps = 8
    const nextFrame = 1000/fps
    let timer = 0

    const animate = (timestamp) => {
        const deltaTime = timestamp - lastTime
        lastTime = timestamp
        if(timer > nextFrame){
            ctx.fillStyle = 'rgba(255,255,255,0.06)'// 'rgba(0,0,0,0.06)' 
            ctx.textAlign = 'center'
            ctx.fillRect(0,0,canvas.width, canvas.height)
            ctx.fillStyle = 'rgba(0, 127, 197, 1)'
            ctx.font = effect.fontSize + "px monospace"
            effect.symbols.forEach(symbol => symbol.draw())
            timer = 0
        }else{
            timer += deltaTime
        }
        requestAnimationFrame(animate)
    }

    animate(0)

}
export default drawMatrixEffect; 