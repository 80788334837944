import "./Cochabamba.css";

function Cochabamba({ height, width }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width} //"2400"
			height={height} //"2000"
			viewBox={`0 0 1200 1200`} //"0 0 2400 1600"
		>
			<g
				id="paths"
				className="circuit"
				fill="none"
				fillRule="evenodd"
				strokeLinecap="butt"
				strokeLinejoin="round"
				strokeOpacity="1"
			>
				{/* Cochabamba */}
				<path className="st0" d="M569.5,532.2l2-257l22.5-21.7v90.8" />
				<path
					className="st0"
					d="M569.5,453.6l-30-25v-18.9l-47.1-45.4c0.2-19.6,0.3-39.1,0.5-58.7l26.8-11.3l-1.6,48.7"
				/>
				<path className="st0" d="M539.5,409.7l2.2-116.9" />
				<path className="st0" d="M632.3,398.2l-62.2,56.7" />
				<path
					className="st0"
					d="M570.5,400.2l45.7-40c-0.3-42.8-0.5-85.6-0.8-128.4l2.4-21.4"
				/>
				<path
					className="st0"
					d="M594,530.7l0.1-39.3l70.6-76.2l0.1-67.3l22.4-23.6L691,98.4c4.6-5.1,9.1-10.3,13.7-15.5"
				/>
				<path
					className="st0"
					d="M619.1,532.2V499l85.2-84.5v-51.3l48-49l1.5-53.1l-30.1-25l0.4-148.5"
				/>
				<path
					className="st0"
					d="M704.3,414.5l81.2-76.3V235.6l-28.6-23.3l-0.3-130h30.6v112.3"
				/>
				<path
					className="st0"
					d="M785.5,235.6c14.1-14.3,28.3-28.6,42.4-42.9c0.1-34.3,0.2-68.5,0.2-102.8"
				/>
				<path
					className="st0"
					d="M919.3,81.7L861.1,82c-0.3,39.5-0.7,78.9-1,118.3l-40.4,41.3c0.6,21.2,1.2,42.4,1.7,63.6l-35.9,32.9h71.8
			l64.1-50.8c-1-11.7-2.1-23.3-3.1-35l-66.4,59.8"
				/>
				<path className="st0" d="M821.5,305.3l93-79l-6.7-49.1" />
				<path className="st0" d="M619.1,499l56.4,0.2l37.1-35.4" />
				<polyline
					className="st0"
					points="687.3,432.2 719.6,432.2 735.2,418.4 759.8,418.1 		"
				/>
				<path className="st0" d="M736.5,386.5h40.2l26.2-21.1l67.8-1.7" />
				<polyline
					className="st0"
					points="648.2,558.3 687.1,558.3 730.5,514.2 754.6,489.6 790.6,489.6 820.2,458.7 820.2,423.8 851.9,394.9 
					"
				/>
				<polyline
					className="st0"
					points="729.8,514.8 727.3,481.2 812.1,398.1 		"
				/>
				<path
					className="st0"
					d="M873.7,456.7L855,468.4l-34.7-9.7l74.5-60.6l22.5-75.9"
				/>
				<path className="st0" d="M648.2,583.8H881l26.5,27.6h74l20.2-15.2" />
				<path className="st0" d="M731.2,583.8l27.8-30.1l74.2-0.7" />
				<path
					className="st0"
					d="M769.7,553.7l47.5-47.7H867c7.3-4.7,14.5-9.5,21.8-14.2"
				/>
				<path
					className="st0"
					d="M867.2,583.8l63.3-55.3l-18-24l-29.6,26.2"
				/>
				<path
					className="st0"
					d="M907.5,611.4l61.4-56l24.4,15.8L973.1,587"
				/>
				<polyline
					className="st0"
					points="813.4,583.8 829.8,609.2 864.2,609.2 		"
				/>
				<polyline
					className="st0"
					points="774.6,584.8 824.8,636.4 867.2,636.4 		"
				/>
				<path
					className="st0"
					d="M689,584.8l28.1,29.1h44.2l55.1,59H919l33,35.2h62.3v9"
				/>
				<path className="st0" d="M886.9,672.9l30.1-30.6h42" />
				<path
					className="st0"
					d="M919,672.9l52.6-1.5l12.7,16.1l62.8-1l27.6-38.1l-53.3,10.5"
				/>
				<path className="st0" d="M971.6,671.4l52.6-56.9l24.4,11.5" />
				<polyline
					className="st0"
					points="648.2,607 679,607 718.4,645.3 748,645.3 808.2,703.3 848.3,703.3 		"
				/>
				<path
					className="st0"
					d="M648.2,633h27.1l44.6,42.9H741l54.1,56.2h186"
				/>
				<line className="st0" x1="904.5" y1="694.6" x2="876.9" y2="732.1" />
				<path
					className="st0"
					d="M817.5,732.1l29.9,34.7h48.4l34.7,36.1L913,815.5"
				/>
				<path className="st0" d="M941.1,766.7l-45.3,0.1" />
				<polyline
					className="st0"
					points="847.4,766.8 848.3,788.9 897.1,838.3 897.1,865.8 926.3,896.4 		"
				/>
				<path className="st0" d="M675.2,633v50.7l119.5,115.8" />
				<path className="st0" d="M719.8,675.9v14.3l44.2,48" />
				<polyline
					className="st0"
					points="795,732.1 795,761.7 822.6,794.4 822.6,812.1 822.6,831.2 843,848.6 886.8,892.2 		"
				/>
				<polyline
					className="st0"
					points="619.1,658.9 619.1,692.1 658.5,733.3 683.4,759.3 683.4,793 714,820.6 750.8,820.6 808.8,883.2 
			830.1,883.2 870.1,922.6 908.6,922.6 953.2,922.6 976.7,942.7 882,942.7 		"
				/>
				<path className="st0" d="M658.5,733.3l32.8-1.2l127.9,128.3" />
				<path
					className="st0"
					d="M808.8,883.2V903l57.7,59.6l9.4,9.6h118.7l9.5,34.2"
				/>
				<polyline
					className="st0"
					points="629.9,703.3 629.9,747.2 658.5,776.8 		"
				/>
				<polyline
					className="st0"
					points="683.4,793 683.4,846.8 725.6,894.8 		"
				/>
				<polyline
					className="st0"
					points="714,820.6 714,843.9 786.9,922.6 836.2,972.8 836.2,1024.2 849.9,1070.4 829.1,1089 		"
				/>
				<path
					className="st0"
					d="M594.1,658.9v221.2c0,24.8,0,49.6-0.1,74.5"
				/>
				<path className="st0" d="M594.1,745l29.4,31.8v204.6" />
				<path
					className="st0"
					d="M623.5,787.9l39,39.3V874l57.3,61l-3.2,147.7l-0.1,7.8l10.8,16.3"
				/>
				<path
					className="st0"
					d="M594.1,838.5L573.8,854v88.7L540.7,915c0.5-23.4,1-46.9,1.5-70.3l31.7-32.7"
				/>
				<path
					className="st0"
					d="M570.4,658.9v39.4L528.9,740v33.5l-78,75.5l22,25l17-17"
				/>
				<path
					className="st0"
					d="M691.7,905.1l-1.2,63.2v86.1L662,1028V920.8"
				/>
				<path
					className="st0"
					d="M719.8,935l30.6-0.6c18.7,18.1,37.4,36.2,56.2,54.3v115"
				/>
				<polyline
					className="st0"
					points="836.2,972.8 906,1034.7 1011.9,1034.7 1039.4,1052.9 1070.3,1098.1 1058.3,1116.8 999,1065.3 		"
				/>
				<path
					className="st0"
					d="M906,1034.7l-0.1,68.3l23.9,13.9c-0.3-11.3-0.7-22.7-1-34.1"
				/>
				<line
					className="st0"
					x1="876.1"
					y1="1008.1"
					x2="876.1"
					y2="1079.1"
				/>
				<path className="st0" d="M906,1034.7l95.5,71.3v23.5l36.9,6.2" />
				<path
					className="st0"
					d="M963.5,1136.4l-7.9-4.6c0.1-20,0.2-40,0.3-59.9"
				/>
				<path
					className="st0"
					d="M544.8,532.2v-39.3l-13.3-11.2h-15.7l-36.2-35.2v-31.9l-27.3-27.1h-33.5l-31.2-33.4l-38.3-40.9v-37.9"
				/>
				<polyline
					className="st0"
					points="534.5,484.1 534.5,453.6 509.4,427 		"
				/>
				<path className="st0" d="M467.5,402.5v-96.9l-22.3-2.2" />
				<path
					className="st0"
					d="M445.1,387.5v-31.4L418.9,327v-30.1l-34.1-13.1v32.1"
				/>
				<path className="st0" d="M431.9,435l43.4,43.8h37.5" />
				<polyline
					className="st0"
					points="515.9,607 284.6,609.2 260.8,586.2 142.9,586.2 109.6,546.9 		"
				/>
				<path
					className="st0"
					d="M291.7,609.2l-20.4,23.7l-124.6-0.5c-0.5-8.4-1-16.8-1.5-25.2"
				/>
				<polyline
					className="st0"
					points="196.9,743.4 155.7,779.1 138.9,748.3 254.4,653.3 271.3,632.8 		"
				/>
				<path className="st0" d="M386.5,608.2L338.9,551l-171.6-0.3" />
				<path
					className="st0"
					d="M339.2,608.7c-7.1-8.9-14.2-17.9-21.3-26.8l-30.8,0.1"
				/>
				<path
					className="st0"
					d="M515.9,583.8l-61.8,0.5l-12.3-11.8h-40.6l-55.9-57.7l-102.5,0.4l-125.6,1l11.8-47.7l46-14.4"
				/>
				<path
					className="st0"
					d="M515.9,558.3h-39.6v-21.7l-22.2-19.7h-33.2l-56.7-55.4h-14.8l-36.7-36.1l-85-4.1l-26.6-31l-15.7-17
			c-4.2-15-8.3-30-12.5-45l81.9,64"
				/>
				<path className="st0" d="M390.9,487.6L158.4,489" />
				<polyline
					className="st0"
					points="388.6,517.6 416.5,545.8 476.3,545.8 		"
				/>
				<path className="st0" d="M467.5,528.8v-26.2l-33.6-30" />
				<polyline
					className="st0"
					points="399.7,496.7 402.1,472 388.2,456.7 388.2,406.4 		"
				/>
				<path
					className="st0"
					d="M387.7,354.1h-37.2l-18.9-20.5H304L251.9,295l-3.1-76.3l24-30.9c-5.4-13.3-11.2-26.8-17.4-40.6
			c-8.2-18.4-16.6-36-25-52.8c-8.7-8.9-17.5-17.9-26.2-26.8l3.1,36.6c9.3,27.7,18.6,55.5,27.9,83.2"
				/>
				<line className="st0" x1="356.3" y1="461.2" x2="356.3" y2="384.7" />
				<polyline
					className="st0"
					points="356.3,414.5 317.8,376.9 317.8,360.5 		"
				/>
				<path
					className="st0"
					d="M441.9,607.7l-29.6,29.2h-18.4l-43.4,45.3H306l-19.2,13.4l-31.1-1L229.4,716c-1.4,14.8-2.7,29.6-4.1,44.4
			l-45.1,37"
				/>
				<path className="st0" d="M393.9,636.9l-60.9,0.9h-15.7L296,661.9" />
				<polyline
					className="st0"
					points="452.6,653.3 416,653.3 397.9,668.6 		"
				/>
				<path
					className="st0"
					d="M380.4,703.3l-12.1,15.3h-22.5L316.7,745l-20.4,18.7l-5.8,32l-78.3,69.4l-9.2,23.8"
				/>
				<polyline
					className="st0"
					points="400.8,786.4 504,686.5 544.8,686.5 		"
				/>
				<path
					className="st0"
					d="M171.9,295.7l44.6,25.8c33,28.2,65.9,56.3,98.9,84.5c-0.9,6.4-1.7,12.9-2.6,19.4"
				/>
				<path
					className="st0"
					d="M349.4,313.2l-31.7-25h-29.3v-82.3l36.3,37l0.6,18.5"
				/>
				<path
					className="st0"
					d="M216.5,321.5c-0.4-12.7-0.7-25.5-1.1-38.2l-65.8-41.1l-19.7-62.4l22.6-28.9l30.5,75.7"
				/>
				<path
					className="st0"
					d="M250.6,261.9l-29.8-17.2l-35.3-110.3l-8.7-84.9l-31,54.3"
				/>
				<path
					className="st0"
					d="M188.4,928.1c1.5-4.6,3.1-9.1,4.6-13.7l129.9-111.6l-2-22.5l47.4-42.9l0.1-18.9"
				/>
				<polyline
					className="st0"
					points="495.2,745 474.5,745.7 417.2,800.8 376.7,853.4 376.7,888.5 317,897.7 300.4,928.8 206.9,973.1 
			194.6,952.9 356.6,835.5 356.6,793 414.5,732.7 454.3,701.2 		"
				/>
				<polyline
					className="st0"
					points="402.9,872.6 425,856.2 434.5,825.3 495.2,769.2 495.2,745 544.8,696.3 544.8,658.9 		"
				/>
				<polyline
					className="st0"
					points="750.5,934.4 750.5,1126.2 776.4,1141.6 776.4,994.2 		"
				/>
				<path
					className="st0"
					d="M594,703.3l-29.1,28.8v43.6L511,831.1c0.2,19.3,0.5,38.6,0.7,57.9"
				/>
				<polyline
					className="st0"
					points="875.9,972.2 911.5,1003.7 969.6,1003.7 		"
				/>
				<path
					className="st0"
					d="M197.5,842.8c-2.7-4.1-5.4-8.2-8.1-12.3l64.7-54.1l12.6-26.9l23.8-19.6l33.8-26.5h56.2l13.5-11.8h32
			l26.5-27.2v-23.5l14-10.2h49.4"
				/>
				<path className="st0" d="M235.9,668.6l-67.4,1.2l-10.3-11.9" />
				<path
					className="st0"
					d="M349.4,461.5l-140.3-2.3l-25.7-42.3l3.6-5.1"
				/>
				<path
					className="st0"
					d="M664.7,347.9L644,324.4V155.7l23.3-29.7v184.6"
				/>
				<line className="st0" x1="687.1" y1="324.2" x2="723.5" y2="288.1" />
				<path className="st0" d="M181.9,713c-1.5-6.1-3-12.3-4.5-18.4" />
				<path
					className="st0"
					d="M916.7,572.1l33.7-30c-6.7-4.5-13.3-9.1-20-13.6"
				/>
				<polyline
					className="st0"
					points="881.8,107.7 915.6,107.7 887.5,147.8 887,211.9 821.5,273.5 		"
				/>
			</g>
			<g id="2nd-path">
				{/* Cochabamba */}
				<path className="st0-path" d="M569.5,532.2l2-257l22.5-21.7v90.8" />
				<path
					className="st0-path"
					d="M569.5,453.6l-30-25v-18.9l-47.1-45.4c0.2-19.6,0.3-39.1,0.5-58.7l26.8-11.3l-1.6,48.7"
				/>
				<path className="st0-path" d="M539.5,409.7l2.2-116.9" />
				<path className="st0-path" d="M632.3,398.2l-62.2,56.7" />
				<path
					className="st0-path"
					d="M570.5,400.2l45.7-40c-0.3-42.8-0.5-85.6-0.8-128.4l2.4-21.4"
				/>
				<path
					className="st0-path"
					d="M594,530.7l0.1-39.3l70.6-76.2l0.1-67.3l22.4-23.6L691,98.4c4.6-5.1,9.1-10.3,13.7-15.5"
				/>
				<path
					className="st0-path"
					d="M619.1,532.2V499l85.2-84.5v-51.3l48-49l1.5-53.1l-30.1-25l0.4-148.5"
				/>
				<path
					className="st0-path"
					d="M704.3,414.5l81.2-76.3V235.6l-28.6-23.3l-0.3-130h30.6v112.3"
				/>
				<path
					className="st0-path"
					d="M785.5,235.6c14.1-14.3,28.3-28.6,42.4-42.9c0.1-34.3,0.2-68.5,0.2-102.8"
				/>
				<path
					className="st0-path"
					d="M919.3,81.7L861.1,82c-0.3,39.5-0.7,78.9-1,118.3l-40.4,41.3c0.6,21.2,1.2,42.4,1.7,63.6l-35.9,32.9h71.8
			l64.1-50.8c-1-11.7-2.1-23.3-3.1-35l-66.4,59.8"
				/>
				<path className="st0-path" d="M821.5,305.3l93-79l-6.7-49.1" />
				<path className="st0-path" d="M619.1,499l56.4,0.2l37.1-35.4" />
				<polyline
					className="st0-path"
					points="687.3,432.2 719.6,432.2 735.2,418.4 759.8,418.1 		"
				/>
				<path
					className="st0-path"
					d="M736.5,386.5h40.2l26.2-21.1l67.8-1.7"
				/>
				<polyline
					className="st0-path"
					points="648.2,558.3 687.1,558.3 730.5,514.2 754.6,489.6 790.6,489.6 820.2,458.7 820.2,423.8 851.9,394.9 
					"
				/>
				<polyline
					className="st0-path"
					points="729.8,514.8 727.3,481.2 812.1,398.1 		"
				/>
				<path
					className="st0-path"
					d="M873.7,456.7L855,468.4l-34.7-9.7l74.5-60.6l22.5-75.9"
				/>
				<path
					className="st0-path"
					d="M648.2,583.8H881l26.5,27.6h74l20.2-15.2"
				/>
				<path className="st0-path" d="M731.2,583.8l27.8-30.1l74.2-0.7" />
				<path
					className="st0-path"
					d="M769.7,553.7l47.5-47.7H867c7.3-4.7,14.5-9.5,21.8-14.2"
				/>
				<path
					className="st0-path"
					d="M867.2,583.8l63.3-55.3l-18-24l-29.6,26.2"
				/>
				<path
					className="st0-path"
					d="M907.5,611.4l61.4-56l24.4,15.8L973.1,587"
				/>
				<polyline
					className="st0-path"
					points="813.4,583.8 829.8,609.2 864.2,609.2 		"
				/>
				<polyline
					className="st0-path"
					points="774.6,584.8 824.8,636.4 867.2,636.4 		"
				/>
				<path
					className="st0-path"
					d="M689,584.8l28.1,29.1h44.2l55.1,59H919l33,35.2h62.3v9"
				/>
				<path className="st0-path" d="M886.9,672.9l30.1-30.6h42" />
				<path
					className="st0-path"
					d="M919,672.9l52.6-1.5l12.7,16.1l62.8-1l27.6-38.1l-53.3,10.5"
				/>
				<path className="st0-path" d="M971.6,671.4l52.6-56.9l24.4,11.5" />
				<polyline
					className="st0-path"
					points="648.2,607 679,607 718.4,645.3 748,645.3 808.2,703.3 848.3,703.3 		"
				/>
				<path
					className="st0-path"
					d="M648.2,633h27.1l44.6,42.9H741l54.1,56.2h186"
				/>
				<line
					className="st0-path"
					x1="904.5"
					y1="694.6"
					x2="876.9"
					y2="732.1"
				/>
				<path
					className="st0-path"
					d="M817.5,732.1l29.9,34.7h48.4l34.7,36.1L913,815.5"
				/>
				<path className="st0-path" d="M941.1,766.7l-45.3,0.1" />
				<polyline
					className="st0-path"
					points="847.4,766.8 848.3,788.9 897.1,838.3 897.1,865.8 926.3,896.4 		"
				/>
				<path className="st0-path" d="M675.2,633v50.7l119.5,115.8" />
				<path className="st0-path" d="M719.8,675.9v14.3l44.2,48" />
				<polyline
					className="st0-path"
					points="795,732.1 795,761.7 822.6,794.4 822.6,812.1 822.6,831.2 843,848.6 886.8,892.2 		"
				/>
				<polyline
					className="st0-path"
					points="619.1,658.9 619.1,692.1 658.5,733.3 683.4,759.3 683.4,793 714,820.6 750.8,820.6 808.8,883.2 
			830.1,883.2 870.1,922.6 908.6,922.6 953.2,922.6 976.7,942.7 882,942.7 		"
				/>
				<path className="st0-path" d="M658.5,733.3l32.8-1.2l127.9,128.3" />
				<path
					className="st0-path"
					d="M808.8,883.2V903l57.7,59.6l9.4,9.6h118.7l9.5,34.2"
				/>
				<polyline
					className="st0-path"
					points="629.9,703.3 629.9,747.2 658.5,776.8 		"
				/>
				<polyline
					className="st0-path"
					points="683.4,793 683.4,846.8 725.6,894.8 		"
				/>
				<polyline
					className="st0-path"
					points="714,820.6 714,843.9 786.9,922.6 836.2,972.8 836.2,1024.2 849.9,1070.4 829.1,1089 		"
				/>
				<path
					className="st0-path"
					d="M594.1,658.9v221.2c0,24.8,0,49.6-0.1,74.5"
				/>
				<path className="st0-path" d="M594.1,745l29.4,31.8v204.6" />
				<path
					className="st0-path"
					d="M623.5,787.9l39,39.3V874l57.3,61l-3.2,147.7l-0.1,7.8l10.8,16.3"
				/>
				<path
					className="st0-path"
					d="M594.1,838.5L573.8,854v88.7L540.7,915c0.5-23.4,1-46.9,1.5-70.3l31.7-32.7"
				/>
				<path
					className="st0-path"
					d="M570.4,658.9v39.4L528.9,740v33.5l-78,75.5l22,25l17-17"
				/>
				<path
					className="st0-path"
					d="M691.7,905.1l-1.2,63.2v86.1L662,1028V920.8"
				/>
				<path
					className="st0-path"
					d="M719.8,935l30.6-0.6c18.7,18.1,37.4,36.2,56.2,54.3v115"
				/>
				<polyline
					className="st0-path"
					points="836.2,972.8 906,1034.7 1011.9,1034.7 1039.4,1052.9 1070.3,1098.1 1058.3,1116.8 999,1065.3 		"
				/>
				<path
					className="st0-path"
					d="M906,1034.7l-0.1,68.3l23.9,13.9c-0.3-11.3-0.7-22.7-1-34.1"
				/>
				<line
					className="st0-path"
					x1="876.1"
					y1="1008.1"
					x2="876.1"
					y2="1079.1"
				/>
				<path
					className="st0-path"
					d="M906,1034.7l95.5,71.3v23.5l36.9,6.2"
				/>
				<path
					className="st0-path"
					d="M963.5,1136.4l-7.9-4.6c0.1-20,0.2-40,0.3-59.9"
				/>
				<path
					className="st0-path"
					d="M544.8,532.2v-39.3l-13.3-11.2h-15.7l-36.2-35.2v-31.9l-27.3-27.1h-33.5l-31.2-33.4l-38.3-40.9v-37.9"
				/>
				<polyline
					className="st0-path"
					points="534.5,484.1 534.5,453.6 509.4,427 		"
				/>
				<path className="st0-path" d="M467.5,402.5v-96.9l-22.3-2.2" />
				<path
					className="st0-path"
					d="M445.1,387.5v-31.4L418.9,327v-30.1l-34.1-13.1v32.1"
				/>
				<path className="st0-path" d="M431.9,435l43.4,43.8h37.5" />
				<polyline
					className="st0-path"
					points="515.9,607 284.6,609.2 260.8,586.2 142.9,586.2 109.6,546.9 		"
				/>
				<path
					className="st0-path"
					d="M291.7,609.2l-20.4,23.7l-124.6-0.5c-0.5-8.4-1-16.8-1.5-25.2"
				/>
				<polyline
					className="st0-path"
					points="196.9,743.4 155.7,779.1 138.9,748.3 254.4,653.3 271.3,632.8 		"
				/>
				<path className="st0-path" d="M386.5,608.2L338.9,551l-171.6-0.3" />
				<path
					className="st0-path"
					d="M339.2,608.7c-7.1-8.9-14.2-17.9-21.3-26.8l-30.8,0.1"
				/>
				<path
					className="st0-path"
					d="M515.9,583.8l-61.8,0.5l-12.3-11.8h-40.6l-55.9-57.7l-102.5,0.4l-125.6,1l11.8-47.7l46-14.4"
				/>
				<path
					className="st0-path"
					d="M515.9,558.3h-39.6v-21.7l-22.2-19.7h-33.2l-56.7-55.4h-14.8l-36.7-36.1l-85-4.1l-26.6-31l-15.7-17
			c-4.2-15-8.3-30-12.5-45l81.9,64"
				/>
				<path className="st0-path" d="M390.9,487.6L158.4,489" />
				<polyline
					className="st0-path"
					points="388.6,517.6 416.5,545.8 476.3,545.8 		"
				/>
				<path className="st0-path" d="M467.5,528.8v-26.2l-33.6-30" />
				<polyline
					className="st0-path"
					points="399.7,496.7 402.1,472 388.2,456.7 388.2,406.4 		"
				/>
				<path
					className="st0-path"
					d="M387.7,354.1h-37.2l-18.9-20.5H304L251.9,295l-3.1-76.3l24-30.9c-5.4-13.3-11.2-26.8-17.4-40.6
			c-8.2-18.4-16.6-36-25-52.8c-8.7-8.9-17.5-17.9-26.2-26.8l3.1,36.6c9.3,27.7,18.6,55.5,27.9,83.2"
				/>
				<line
					className="st0-path"
					x1="356.3"
					y1="461.2"
					x2="356.3"
					y2="384.7"
				/>
				<polyline
					className="st0-path"
					points="356.3,414.5 317.8,376.9 317.8,360.5 		"
				/>
				<path
					className="st0-path"
					d="M441.9,607.7l-29.6,29.2h-18.4l-43.4,45.3H306l-19.2,13.4l-31.1-1L229.4,716c-1.4,14.8-2.7,29.6-4.1,44.4
			l-45.1,37"
				/>
				<path
					className="st0-path"
					d="M393.9,636.9l-60.9,0.9h-15.7L296,661.9"
				/>
				<polyline
					className="st0-path"
					points="452.6,653.3 416,653.3 397.9,668.6 		"
				/>
				<path
					className="st0-path"
					d="M380.4,703.3l-12.1,15.3h-22.5L316.7,745l-20.4,18.7l-5.8,32l-78.3,69.4l-9.2,23.8"
				/>
				<polyline
					className="st0-path"
					points="400.8,786.4 504,686.5 544.8,686.5 		"
				/>
				<path
					className="st0-path"
					d="M171.9,295.7l44.6,25.8c33,28.2,65.9,56.3,98.9,84.5c-0.9,6.4-1.7,12.9-2.6,19.4"
				/>
				<path
					className="st0-path"
					d="M349.4,313.2l-31.7-25h-29.3v-82.3l36.3,37l0.6,18.5"
				/>
				<path
					className="st0-path"
					d="M216.5,321.5c-0.4-12.7-0.7-25.5-1.1-38.2l-65.8-41.1l-19.7-62.4l22.6-28.9l30.5,75.7"
				/>
				<path
					className="st0-path"
					d="M250.6,261.9l-29.8-17.2l-35.3-110.3l-8.7-84.9l-31,54.3"
				/>
				<path
					className="st0-path"
					d="M188.4,928.1c1.5-4.6,3.1-9.1,4.6-13.7l129.9-111.6l-2-22.5l47.4-42.9l0.1-18.9"
				/>
				<polyline
					className="st0-path"
					points="495.2,745 474.5,745.7 417.2,800.8 376.7,853.4 376.7,888.5 317,897.7 300.4,928.8 206.9,973.1 
			194.6,952.9 356.6,835.5 356.6,793 414.5,732.7 454.3,701.2 		"
				/>
				<polyline
					className="st0-path"
					points="402.9,872.6 425,856.2 434.5,825.3 495.2,769.2 495.2,745 544.8,696.3 544.8,658.9 		"
				/>
				<polyline
					className="st0-path"
					points="750.5,934.4 750.5,1126.2 776.4,1141.6 776.4,994.2 		"
				/>
				<path
					className="st0-path"
					d="M594,703.3l-29.1,28.8v43.6L511,831.1c0.2,19.3,0.5,38.6,0.7,57.9"
				/>
				<polyline
					className="st0-path"
					points="875.9,972.2 911.5,1003.7 969.6,1003.7 		"
				/>
				<path
					className="st0-path"
					d="M197.5,842.8c-2.7-4.1-5.4-8.2-8.1-12.3l64.7-54.1l12.6-26.9l23.8-19.6l33.8-26.5h56.2l13.5-11.8h32
			l26.5-27.2v-23.5l14-10.2h49.4"
				/>
				<path className="st0-path" d="M235.9,668.6l-67.4,1.2l-10.3-11.9" />
				<path
					className="st0-path"
					d="M349.4,461.5l-140.3-2.3l-25.7-42.3l3.6-5.1"
				/>
				<path
					className="st0-path"
					d="M664.7,347.9L644,324.4V155.7l23.3-29.7v184.6"
				/>
				<line
					className="st0-path"
					x1="687.1"
					y1="324.2"
					x2="723.5"
					y2="288.1"
				/>
				<path
					className="st0-path"
					d="M181.9,713c-1.5-6.1-3-12.3-4.5-18.4"
				/>
				<path
					className="st0-path"
					d="M916.7,572.1l33.7-30c-6.7-4.5-13.3-9.1-20-13.6"
				/>
				<polyline
					className="st0-path"
					points="881.8,107.7 915.6,107.7 887.5,147.8 887,211.9 821.5,273.5 		"
				/>
			</g>
			<g id="puntos">
				<circle className="st0" cx="724.1" cy="80.6" r="7" />
				<circle className="st0" cx="828.1" cy="82.9" r="7" />
				<circle className="st0" cx="926.2" cy="82.9" r="7" />
				<circle className="st0" cx="876.3" cy="107.7" r="7" />
				<circle className="st0" cx="907.5" cy="170.2" r="7" />
				<circle className="st0" cx="847.7" cy="315.2" r="7" />
				<circle className="st0" cx="920" cy="315.2" r="7" />
				<circle className="st0" cx="876.4" cy="363.7" r="7" />
				<circle className="st0" cx="857" cy="390.4" r="7" />
				<circle className="st0" cx="817.4" cy="393.1" r="7" />
				<circle className="st0" cx="765.3" cy="417.5" r="7" />
				<circle className="st0" cx="715.9" cy="459.6" r="7" />
				<circle className="st0" cx="787.2" cy="201.4" r="7" />
				<circle className="st0" cx="728.1" cy="283.2" r="7" />
				<circle className="st0" cx="667.3" cy="315.5" r="7" />
				<circle className="st0" cx="619.1" cy="203.9" r="7" />
				<circle className="st0" cx="594.3" cy="350.3" r="7" />
				<ellipse className="st0" cx="541.8" cy="285.7" rx="7" ry="7" />
				<ellipse className="st0" cx="517.4" cy="347.9" rx="7" ry="7" />
				<circle className="st0" cx="440.2" cy="302.1" r="7" />
				<circle className="st0" cx="384.7" cy="322.9" r="7" />
				<circle className="st0" cx="349.4" cy="268.3" r="7" />
				<circle className="st0" cx="325.4" cy="268.3" r="7" />
				<circle className="st0" cx="237.7" cy="194.1" r="7" />
				<ellipse className="st0" cx="165.9" cy="292" rx="7" ry="7" />
				<circle className="st0" cx="259.4" cy="396.8" r="7" />
				<ellipse className="st0" cx="191.5" cy="406.3" rx="7" ry="7" />
				<ellipse className="st0" cx="180" cy="450.4" rx="7" ry="7" />
				<ellipse className="st0" cx="153.5" cy="488.3" rx="7" ry="7" />
				<ellipse className="st0" cx="162.1" cy="550.6" rx="7" ry="7" />
				<ellipse className="st0" cx="280.9" cy="582.7" rx="7" ry="7" />
				<ellipse className="st0" cx="146" cy="601.9" rx="7" ry="7" />
				<ellipse className="st0" cx="155.7" cy="651.3" rx="7" ry="7" />
				<ellipse className="st0" cx="176.3" cy="687.6" rx="7" ry="7" />
				<ellipse className="st0" cx="293.1" cy="665.7" rx="7" ry="7" />
				<ellipse className="st0" cx="201.6" cy="741.4" rx="7" ry="7" />
				<ellipse className="st0" cx="174.9" cy="802" rx="7" ry="7" />
				<ellipse className="st0" cx="201.6" cy="848.5" rx="7" ry="7" />
				<ellipse className="st0" cx="185.8" cy="934.6" rx="7" ry="7" />
				<ellipse className="st0" cx="393.9" cy="672.6" rx="7" ry="7" />
				<ellipse className="st0" cx="459.7" cy="696.3" rx="7" ry="7" />
				<ellipse className="st0" cx="397.5" cy="790.4" rx="7" ry="7" />
				<ellipse className="st0" cx="399.4" cy="875.6" rx="7" ry="7" />
				<ellipse className="st0" cx="496.2" cy="852.4" rx="7" ry="7" />

				<ellipse
					transform="matrix(0.9975 -7.082580e-02 7.082580e-02 0.9975 -62.1802 38.4153)"
					className="st0"
					cx="510.6"
					cy="896"
					rx="7"
					ry="7"
				/>
				<circle className="st0" cx="577.4" cy="809.3" r="7" />
				<circle className="st0" cx="594" cy="961.7" r="7" />
				<circle className="st0" cx="623.5" cy="988.5" r="7" />
				<circle className="st0" cx="662" cy="913.8" r="7" />
				<circle className="st0" cx="728" cy="899.7" r="7" />
				<circle className="st0" cx="776.5" cy="988.5" r="7" />
				<circle className="st0" cx="729.7" cy="1110.9" r="7" />
				<path
					className="st0"
					d="M813.6,1110.8c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7S813.6,1106.9,813.6,1110.8z"
				/>
				<path
					className="st0"
					d="M883.1,1083.9c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7S883.1,1080,883.1,1083.9z"
				/>
				<path
					className="st0"
					d="M935.9,1075.8c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7S935.9,1071.9,935.9,1075.8z"
				/>
				<path
					className="st0"
					d="M976.8,1139.6c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7S976.8,1135.7,976.8,1139.6z"
				/>
				<path
					className="st0"
					d="M1052.4,1136.8c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7S1052.4,1133,1052.4,1136.8z"
				/>
				<path
					className="st0"
					d="M1003.7,1061c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7S1003.7,1057.1,1003.7,1061z"
				/>
				<path
					className="st0"
					d="M981.1,1003.7c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7S981.1,999.9,981.1,1003.7z"
				/>
				<path
					className="st0"
					d="M884.4,941.7c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7S884.4,937.8,884.4,941.7z"
				/>
				<path
					className="st0"
					d="M897,896.4c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7S897,892.6,897,896.4z"
				/>
				<path
					className="st0"
					d="M829.7,863.8c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7l0,0C826.6,856.8,829.7,860,829.7,863.8z"
				/>
				<path
					className="st0"
					d="M804.9,803.3c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7S804.9,799.4,804.9,803.3z"
				/>
				<path
					className="st0"
					d="M773.8,743.2c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7S773.8,739.3,773.8,743.2z"
				/>
				<path
					className="st0"
					d="M916.7,819.2c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7S916.7,815.3,916.7,819.2z"
				/>
				<path
					className="st0"
					d="M955,767.9c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7S955,764.1,955,767.9z"
				/>
				<path
					className="st0"
					d="M1021.3,724.2c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7S1021.3,720.3,1021.3,724.2z"
				/>
				<path
					className="st0"
					d="M995,732.1c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7l0,0C991.9,725.1,995,728.2,995,732.1z"
				/>
				<path
					className="st0"
					d="M914.2,690.8c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7l0,0C911.1,683.8,914.2,686.9,914.2,690.8z"
				/>
				<path
					className="st0"
					d="M860.7,703.3c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7S860.7,699.4,860.7,703.3z"
				/>
				<path
					className="st0"
					d="M881,636.4c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7S881,632.5,881,636.4z"
				/>
				<path
					className="st0"
					d="M876.9,609.2c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7S876.9,605.3,876.9,609.2z"
				/>
				<path
					className="st0"
					d="M973.1,642.3c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7S973.1,638.4,973.1,642.3z"
				/>
				<path
					className="st0"
					d="M1061.8,629.4c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7S1061.8,625.5,1061.8,629.4z"
				/>
				<path
					className="st0"
					d="M1014.3,591.8c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7S1014.3,588,1014.3,591.8z"
				/>
				<path
					className="st0"
					d="M976.6,590.4c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7S976.6,586.5,976.6,590.4z"
				/>
				<path
					className="st0"
					d="M920.6,575.9c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7S920.6,572,920.6,575.9z"
				/>
				<path
					className="st0"
					d="M847.2,553c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7S847.2,549.1,847.2,553z"
				/>
				<path
					className="st0"
					d="M884.9,535.5c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7S884.9,531.7,884.9,535.5z"
				/>
				<path
					className="st0"
					d="M902.1,488.6c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7l0,0C898.9,481.6,902.1,484.7,902.1,488.6z"
				/>
				<path
					className="st0"
					d="M886.8,453.1c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7l0,0C883.6,446.1,886.8,449.2,886.8,453.1z"
				/>
				<path
					className="st0"
					d="M1024.4,660.9c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7S1024.4,657.1,1024.4,660.9z"
				/>
				<path
					className="st0"
					d="M936,900.1c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7S936,896.3,936,900.1z"
				/>
				<path
					className="st0"
					d="M1012.6,1013.2c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7S1012.6,1009.4,1012.6,1013.2z"
				/>
				<circle className="st0" cx="661.1" cy="781.1" r="7" />
				<ellipse className="st0" cx="109.7" cy="541.2" rx="7" ry="7" />
				<circle className="st0" cx="317.8" cy="355.8" r="7" />
				<circle className="st0" cx="356.3" cy="379.4" r="7" />
				<circle className="st0" cx="388.2" cy="401.1" r="7" />
				<circle className="st0" cx="430.5" cy="468.2" r="7" />
				<ellipse className="st0" cx="386.5" cy="512.3" rx="7" ry="7" />
				<circle className="st0" cx="424.8" cy="427" r="10.8" />
				<circle className="st0" cx="184.8" cy="231.6" r="7" />
				<ellipse className="st0" cx="143.1" cy="110.3" rx="7" ry="7" />
				<ellipse
					transform="matrix(0.1602 -0.9871 0.9871 0.1602 8.4853 852.7733)"
					className="st0"
					cx="505.4"
					cy="421.4"
					rx="7"
					ry="7"
				/>
				<circle className="st0" cx="635.9" cy="394.9" r="7" />
			</g>
			<g id="union_circuitos">
				<line className="st5" x1="544.8" y1="532.2" x2="544.8" y2="499.5" />
				<line className="st5" x1="544.8" y1="688.9" x2="544.8" y2="658.9" />
				<line className="st5" x1="570.4" y1="688.9" x2="570.4" y2="658.9" />
				<line className="st5" x1="594" y1="688.9" x2="594" y2="658.9" />
				<line className="st5" x1="619.1" y1="688.9" x2="619.1" y2="658.9" />
				<line className="st5" x1="516" y1="558.3" x2="483.4" y2="558.3" />
				<line className="st5" x1="515.9" y1="583.5" x2="483.2" y2="583.5" />
				<line className="st5" x1="515.9" y1="606.4" x2="483.2" y2="606.4" />
				<line className="st5" x1="515.9" y1="630" x2="483.2" y2="630" />
				<line className="st5" x1="677.8" y1="633" x2="648.2" y2="633" />
				<line className="st5" x1="677.8" y1="607" x2="648.2" y2="607" />
				<line className="st5" x1="677.8" y1="583.8" x2="648.2" y2="583.8" />
				<line className="st5" x1="677.8" y1="558" x2="648.2" y2="558" />
				<line className="st5" x1="569.5" y1="532.2" x2="569.5" y2="499.5" />
				<line className="st5" x1="594.3" y1="532.2" x2="594.3" y2="499.5" />
				<line className="st5" x1="619.1" y1="532.2" x2="619.1" y2="499.5" />
			</g>
			<g id="procesador">
				<path
					className="st6"
					d="M647,651.5c-0.1,3.6-3.1,6.5-6.7,6.4H523.8c-3.6,0.1-6.6-2.8-6.7-6.4V539.7c0.1-3.6,3.1-6.5,6.7-6.4h116.6
			c3.6-0.1,6.6,2.8,6.7,6.4L647,651.5z"
				/>

				{/* <linearGradient
						id="SVGID_1_"
						gradientUnits="userSpaceOnUse"
						x1="582.1"
						y1="658.9022"
						x2="582.1"
						y2="532.1979"
					>
						<stop offset="0" stopColor="#134E8B" />
						<stop offset="2.000000e-02" stopColor="#185390" />
						<stop offset="3.000000e-02" stopColor="#26629E" />
						<stop offset="5.000000e-02" stopColor="#3C7AB6" />
						<stop offset="7.000000e-02" stopColor="#5696D1" />
						<stop offset="0.93" stopColor="#7EACDD" />
						<stop offset="0.93" stopColor="#8CB7E2" />
						<stop offset="0.94" stopColor="#A3C9EA" />
						<stop offset="0.95" stopColor="#B5D8F0" />
						<stop offset="0.96" stopColor="#C2E2F4" />
						<stop offset="0.97" stopColor="#C9E7F6" />
						<stop offset="1" stopColor="#CBE9F7" />
					</linearGradient> */}

				<path
					className="st7"
					d="M640.4,658.9H523.8c-4.2,0.1-7.7-3.3-7.8-7.5V539.7c0.1-4.2,3.6-7.5,7.8-7.5h116.6c4.2-0.1,7.7,3.3,7.8,7.5
			v111.8C648.1,655.7,644.6,659,640.4,658.9z M523.8,534.4c-3-0.1-5.5,2.3-5.5,5.3v111.8c0.1,3,2.5,5.4,5.5,5.3h116.6
			c3,0.1,5.5-2.3,5.5-5.3V539.7c-0.1-3-2.5-5.4-5.5-5.3H523.8z"
				/>
				<path
					className="st8"
					d="M534.3,542.1c0.1,2.5-1.8,4.5-4.3,4.6s-4.5-1.8-4.6-4.3s1.8-4.5,4.3-4.6c0.1,0,0.1,0,0.2,0
			C532.2,537.8,534.2,539.7,534.3,542.1L534.3,542.1z"
				/>
			</g>
			<g id="logocbba">
				<path
					className="st9"
					d="M587.9,573.1l17.9,7.1l-5.8,2.2l-12.6-5v10.8l10.4,3.7v9.3l-10.5-3.2c0,0,0,14.1,0,18c-0.2,4.3,3.2,8,7.5,8.2
			c0.2,0,0.4,0,0.6,0c0.5,0,0.9,0,1.4-0.1s1-0.2,1.5-0.4l-1.7,3.6l-3.3,1h-4l-12.2-2.5l0.6-65.6l0.9-0.8l1.5-0.1l27,13.4v6"
				/>
				<path className="st10" d="M565.2,574.3c0,0.2,0,0.4,0,0.6V574.3z" />
				<path
					className="st9"
					d="M582.3,573.7l-11.9,3.8l-0.2,47.2l-5.6,1.8c-0.2-0.2,0.5-40.5,0.7-51.7c0-0.2,0-0.4,0-0.6l5.3-2l11.9-4.2
			L582.3,573.7z"
				/>
				<polygon
					className="st11"
					points="597.8,601.3 587.4,604 587.4,598 		"
				/>
				<polygon
					className="st11"
					points="582,557.1 588.9,553.7 588.2,564.2 582.5,568 		"
				/>
				<polygon
					className="st11"
					points="588.9,553.7 597.8,558.2 597.2,568.4 588.2,564.2 		"
				/>
				<path
					className="st11"
					d="M589.2,627.8c3.6,0.7,7.2-0.9,9.1-4l-2,8.9l-8.1,3.5L589.2,627.8z"
				/>
				<polygon
					className="st11"
					points="587.9,573.1 597.2,568.5 597.2,567.5 607.1,572.6 607.1,578.6 605.8,580.2 		"
				/>
				<path
					className="st11"
					d="M556.6,576.8v-7.2l30.8-15.1l-0.7,10.5l-4.2,2.9l-0.3,48.8c-0.1,1.9,0.1,3.8,0.5,5.7
			c0.9,2.5,2.9,4.5,5.5,5.3c0.3,0.1,0.6,0.2,0.9,0.2l-1,8.4c-5.7-2.7-8.5-4-11.8-6.8c-3.9-3.7-3.5-9-3.5-14.5l0.3-43.4l-7.2,2.7
			l-1.5,52.5l-6.7-3.2l1.7-47.1L556.6,576.8z"
				/>
				<polygon
					className="st9"
					points="556.6,576.8 559.6,576.3 559.5,577.8 		"
				/>
			</g>
		</svg>
	);
}

export default Cochabamba;
